import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import userAPI from "../services/userAPI";
import {getSettings, setSettings} from "../utils";
import LoadingScreen from "../components/LoadingScreen";

const initialAuthState = {
    user: null,
    roles: {},
    isInitialized: false,
    lang: {},
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateLocale: 'en-gb'
};

const getLang = (user) => {
    
    let lang = getSettings()?.lang;
    
    if (!lang) {
       lang  = user?.lang?.abbr;
    }
    
    return lang;
}

const getLocale = () => {

    let locale = getSettings()?.locale;

    if (!locale) {
        locale  = {id:17};
    }

    return locale;
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const {user, roles} = action.payload;
            
            const lang = getLang(user);
            const locale = getLocale();

            return {
                ...state,
                user,
                locale,
                roles,
                isInitialized: true,
                lang
            };
        }
        case 'CHANGELANG': {
            const {lang} = action.payload;
            setSettings('lang', lang);
    
            return {
                ...state,
                lang
            };
        }
        case 'CHANGELOCALE': {
            const {locale} = action.payload;
            setSettings('locale', locale);

            return {
                ...state,
                locale
            };
        }
        case 'CHANGEDATELOCALE': {
            const {dateLocale} = action.payload;
            setSettings('dateLocale', dateLocale);

            return {
                ...state,
                dateLocale
            };
        }
        case 'CHANGETIMEZONE': {
            const {timezone} = action.payload;
            setSettings('timezone', timezone);

            return {
                ...state,
                timezone
            };
        }
        case 'SETUSER': {
            const {user} = action.payload;
        
            return {
                ...state,
                user
            };
        }
        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
});

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    
    const changeLang = async ({lang}) => {
        
        dispatch({
            type: 'CHANGELANG',
            payload: {
                lang
            }
        });
        
    };

    const changeLocale = async ({locale}) => {

        dispatch({
            type: 'CHANGELOCALE',
            payload: {
                locale
            }
        });

    };


    const changeTimezone = async ({timezone}) => {

        dispatch({
            type: 'CHANGETIMEZONE',
            payload: {
                timezone
            }
        });

    };

    const changeDateLocale = async ({dateLocale}) => {

        dispatch({
            type: 'CHANGEDATELOCALE',
            payload: {
                dateLocale
            }
        });

    };
    
 
    const getRequestUser = async () => {
    
        const response = await userAPI.getRequestUser();
        const user = response.data;
      
        dispatch({
            type: 'SETUSER',
            payload: {
                user
            }
        });
    };
    
    useEffect(() => {
        const initialise = async () => {
            try {
                const response = await userAPI.getRequestUser();
                const user = response.data;
                const roles = {};
                
                user.roles?.map(role => roles[role.id] = true)
                
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        user,
                        roles
                    }
                });
            } catch (err) {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        user: null
                    }
                });
            }
        };
        
        initialise();
    }, [initialAuthState.user]);
    
    if (!state.isInitialized) {
        return <LoadingScreen/>;
    }
    
    return (
        <AuthContext.Provider
            value={{
                ...state,
                getRequestUser,
                changeTimezone,
                changeLang,
                changeDateLocale,
                changeLocale
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
