import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import rubricAPI from "../services/rubricAPI";
import {changeUrl} from "../utils";
import {TABINFOS} from '../constants';
import {SET_REP_SETTINGS} from "./repertorySettings";


export const SET_SELECTED_TRANSLATION = createAsyncThunk(
    "@@TABS/SET_SELECTED_TRANSLATION",
    async (params) => {
        const result = await rubricAPI.get(params);
        return {...params, ...result};
    }
);

const tabSlice = createSlice({
    name: "tab",
    initialState: {
        selectedValue: 0,
        tabItems: []
    },
    extraReducers: {
        [SET_SELECTED_TRANSLATION.fulfilled]: (state, action) => {
            const {data} = action.payload
            changeUrl(`/translations/repertory/${data.documentId}/rubric/${data.id}`);
            const selectedTab = 'translationTab';
            return {
                ...state,
                selectedTranslation: data,
                tabItems: addTabItem(state, 'selectedTranslation', selectedTab, data),
                selectedValue: selectedTab
            };
        }
    },
    reducers: {
        SET_SELECTED_VALUE: (state, action) => {
            const {value} = action.payload;
            return {
                ...state,
                selectedValue: value
            };
        },
        SET_SELECTED_REPERTORY: (state, action) => {
            const document = action.payload;
            const {id, typology} = document;
            changeUrl(typology === 'repertory' ? `/repertories/${id}` : `/materia-medica/${id}`)

            const selectedTypology = typology === 'repertory' ? 'selectedRepertory' : 'selectedMateriaMedica'
            const selectedTab = typology === 'repertory' ? 'repertoryTab' : 'materiaMedicaTab';

            return {
                ...state,
                tabItems: addTabItem(state, selectedTypology, selectedTab, {
                    repertoryId: id,
                    chapterId: state?.repertories?.[id]?.chapter
                }),
                [selectedTypology]: {
                    repertoryId: id,
                    chapterId: state?.repertories?.[id]?.chapter
                },
                selectedValue: selectedTab,
                repertories: {
                    ...state.repertories,
                    [id]: {
                        ...(state?.repertories ? {...state?.repertories[id], typology} : {
                            typology
                        }),
                    },
                },
            };
        },
        SET_SELECTED_CLIPBOARD: (state, action) => {
            const {id, name} = action.payload;
            const selectedTab = 'clipboardTab'
            return {
                ...state,
                tabItems: addTabItem(state, 'selectedClipboard', selectedTab, id),
                selectedClipboard: id,
                selectedValue: selectedTab,
                clipboards: {
                    ...state.clipboards,
                    [id]: {
                        ...(state?.clipboards ? state?.clipboards[id] : {}),
                        name
                    },
                },
            };
        },
        SET_SELECTED_CONSULTATION: (state, action) => {
            const {id} = action.payload;
            const selectedTab = 'consultationTab'
            return {
                ...state,
                tabItems: addTabItem(state, 'selectedConsultation', selectedTab, id),
                selectedConsultation: id,
                selectedValue: selectedTab
            };
        },
        SET_SELECTED_CHAPTER: (state, action) => {
            const {id, chapter} = action.payload;

            const newState = JSON.parse(JSON.stringify(state));
            const doc = newState?.repertories[id] || {};
            changeUrl(`/${doc.typology === 'repertory' ? 'repertories' : 'materia-medica'}/${id}/chapters/${chapter}`);
            const selectedTypology = doc.typology === 'repertory' ? 'selectedRepertory' : 'selectedMateriaMedica';
            const selectedTab = doc.typology === 'repertory' ? 'repertoryTab' : 'materiaMedicaTab';
            return {
                ...state,
                [doc.typology === 'repertory' ? 'selectedRepertory' : 'selectedMateriaMedica']: {
                    chapterId: chapter,
                    repertoryId: id
                },
                tabItems: addTabItem(state, selectedTypology, selectedTab, {
                    chapterId: chapter,
                    repertoryId: id
                }),
                selectedValue: selectedTab,
                repertories: {
                    ...state.repertories,
                    [id]: {
                        ...(state?.repertories ? {
                            ...state?.repertories[id], typology: doc.typology
                        } : {typology: doc.typology}),
                        chapter
                    },
                },
            };
        },

        SET_SELECTED_PATIENT: (state, action) => {
            const {id} = action.payload;
            changeUrl(`/patients/${id}`);
            const selectedTab = 'patientTab'
            return {
                ...state,
                tabItems: addTabItem(state, 'selectedPatient', selectedTab, id),
                selectedPatient: id,
                selectedValue: selectedTab,
            };
        },
        SET_SELECTED_REMEDY: (state, action) => {
            const {id} = action.payload;
            changeUrl(`/remedies/${id}`);
            const selectedTab = 'remedyTab'

            return {
                ...state,
                tabItems: addTabItem(state, 'selectedRemedy', selectedTab, id),
                selectedRemedy: id,
                selectedValue: selectedTab,
            };
        },
        SET_SELECTED_SEARCH: (state, action) => {
            const {id} = action.payload;
            const selectedTab = `searchTab-${id}`;
            return {
                ...state,
                selectedValue: selectedTab,
                tabItems: addTabItem(state, 'searches', selectedTab, {...state.tabItems.searches, [id]: {}}),
                searches: {
                    ...state.searches,
                    [id]: {},
                },
            };
        },
        OPEN_TRANSLATION_TAB: (state, action) => {
            changeUrl(`/translations/list`);
            const selectedTab = 'allTranslationsTab'
            return {
                ...state,
                tabItems: addTabItem(state, 'allTranslations', selectedTab, true),
                selectedValue: selectedTab,
                allTranslations: true
            };
        },
        REMOVE_SEARCH: (state, action) => {
            const {id} = action.payload;
            const tabItems = JSON.parse(JSON.stringify(state)).tabItems.filter((item) => !item['searches']);
            return {
                ...state,
                searches: {...state.searches, [id]: null},
                tabItems
            }
        },
        REMOVE_REPERTORY: (state, action) => {
            const {id} = action.payload;
            delete state.repertories[id];
        },
        REMOVE_SELECTED_REPERTORY: (state, action) => {
            const {typology} = action.payload;

            const tabItems = JSON.parse(JSON.stringify(state)).tabItems.filter((item) => !item[typology === 'repertory' ? 'selectedRepertory' : 'selectedMateriaMedica'])
            const tabValue = tabItems[tabItems.length - 1]?.tab;
            return {
                ...state,
                selectedValue: tabValue,
                tabItems,
                [typology === 'repertory' ? 'selectedRepertory' : 'selectedMateriaMedica']: null
            }
        },
        REMOVE_TAB: (state, action) => {
            const {id} = action.payload
            const tabInfo = TABINFOS.filter(tab => tab.id === id)[0];
            const tabItems = JSON.parse(JSON.stringify(state)).tabItems.filter((item) => !item[id])
            const tabValue = tabItems[tabItems.length - 1]?.tab;

            return {
                ...state,
                tabItems,
                selectedValue: tabValue,
                [tabInfo.id]: false
            }

        }
    },
});

const addTabItem = (state, type, tab, object) => {
    const newState = JSON.parse(JSON.stringify(state));
    if (newState.tabItems.findIndex(tab => tab[type]) == -1) {
        newState.tabItems = [...newState.tabItems, {[type]: object, tab},]
    }
    return newState.tabItems

}

export const {
    SET_SELECTED_REPERTORY,
    SET_SELECTED_CHAPTER,
    SET_SELECTED_SEARCH,
    SET_SELECTED_PATIENT,
    REMOVE_SEARCH,
    REMOVE_REPERTORY,
    REMOVE_SELECTED_REPERTORY,
    SET_SELECTED_CONSULTATION,
    SET_SELECTED_VALUE,
    SET_SELECTED_CLIPBOARD,
    SET_SELECTED_REMEDY,
    OPEN_TRANSLATION_TAB,
    REMOVE_TAB
} = tabSlice.actions;

export default tabSlice.reducer;
