import React from "react";
import {Badge, IconButton} from "@mui/material";
import {Clipboard as ClipboardIcon} from "react-feather";
import {useDrag, useDrop} from "react-dnd";
import useAuth from "../../hooks/useAuth";
import {SET_SELECTED_CLIPBOARD} from "../../redux/tabs";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import useClipboard from "../../hooks/useClipboard";

const Clipboard = ({clipboard, setSelectedClipboard}) => {
    const {addClipboardRubric} = useClipboard();
    const {enqueueSnackbar} = useSnackbar();
    
    const selectClip = () => {
        setSelectedClipboard({id: clipboard.id, name: clipboard.name})
    };
    
    const [{isActive}, drop] = useDrop(() => ({
        accept: 'rubric',
        collect: (monitor) => ({
            isActive: monitor.canDrop() && monitor.isOver(),
        }),
        drop(item, monitor) {
            handleDrop(item)
        },
    }));
    
    const [{opacity}, dragRef] = useDrag(
        () => ({
            type: 'rubric',
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            }),
            item: clipboard
        }),
        []
    );
    
    
    const handleDrop = async (rubric) => {
        try {
            await addClipboardRubric({
                clipboardId: clipboard.id,
                clipboardRubric: {rubric: rubric, intensity: 1}
            })
            enqueueSnackbar('Rubric added', {
                variant: 'success'
            });
        } catch (e) {
            enqueueSnackbar('Rubric already added', {
                variant: 'error'
            });
        }
        
    }
    return (
        <div ref={drop} key={clipboard.id}>
            <IconButton
                sx={{
                    color:'white',
                    marginLeft: '.6rem'
                }}
                tabIndex={-1}
                id={`clip-${clipboard.id}`}
                onClick={selectClip}
                color={'secondary'}
                ref={dragRef}
                style={{opacity}}
            >
                <Badge badgeContent={clipboard?.rubrics?.length || 0} color={"secondary"}>
                    <ClipboardIcon/>
                </Badge>
            </IconButton>
        </div>
    )
}

export default connect(
    ({search: {repertories = {}}}) => ({repertories}),
    (dispatch) => ({
        setSelectedClipboard: (payload) => dispatch(SET_SELECTED_CLIPBOARD(payload)),
    })
)(Clipboard);
