export default {
    HOME: '/',
    REPERTORIES: '/repertories',
    TRANSLATIONS: '/translations',
    MATERIAMEDICA: '/materia-medica',
    PATIENTS: '/patients',
    REMEDIES: '/remedies',
    AUTHORS: '/authors'
    
}
