import {createSlice} from "@reduxjs/toolkit";

const search = createSlice({
    name: "@@SEARCH",
    initialState: {
        queries: {}
    },
    reducers: {
        SET_RESULTS: (state, action) => {
            const {key, data} = action.payload;
            return {...state, [key]: data};
        },
        SET_QUERY_STATE: (state, action) => {
            const {id, advancedContent, mentionContent, searchInputs, searchId} = action.payload;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [id]: {
                        id,
                        ...state.queries[id],
                        searchId,
                        searchInputs,
                        advancedContent,
                        mentionContent
                    }
                }
            }
        },
        SET_QUERY_RESULT: (state, action) => {
            const {id,  documents, rubrics} = action.payload;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [id]: {
                        ...state.queries[id],
                        documents,
                        rubrics
                    }
                }
            }
        },
        SET_QUERY_SELECTED_REPERTORY: (state, action) => {
            const {id,  document } = action.payload;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [id]: {
                        ...state.queries[id],
                        selectedRepertory: document
                    }
                }
            }
        },
        SET_RESULT_PAGE: (state, action) => {
            const {id, page} = action.payload;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [id]: {
                        ...state.queries[id],
                        page: {
                            ...state.queries[id].page,
                            ...page
                        }
                    }
                }
            }
        },
        SET_DOCUMENT_TYPE: (state, action) => {
            const {id, documentType} = action.payload;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [id]: {
                        ...state.queries[id],
                        documentType
                    }
                }
            }
        },
        SET_DOCUMENTS: (state, action) => {
            const {id, selectedDocuments} = action.payload;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [id]: {
                        ...state.queries[id],
                        selectedDocuments
                    }
                }
            }
        },

    },
});

export const {
    SET_RESULTS,
    SET_QUERY_STATE,
    SET_DOCUMENT_TYPE,
    SET_QUERY_RESULT,
    SET_QUERY_SELECTED_REPERTORY,
    SET_RESULT_PAGE,
    SET_DOCUMENTS,
} = search.actions;

export default search.reducer;
